import PageHero from "../components/sections/PageHero";
import ContactUs from "../components/sections/ContactUs";

const OurCompany = () => {
    const txtHEroBody = <>
        <div>
            <h3 className="text-lg text-gray-400 uppercase mb-2">
                Our Motivation
            </h3>
            <h1 className="font-semibold lg:leading-normal text-4xl lg:text-5xl mb-5">
                Let's get{" "}
                <span className="text-primary">IT</span>{" "}
                done.
            </h1>
            <p className="text-lg max-w-xl mb-5">
                we strive to deliver excellence to our
                customers and colleagues. Our values are the
                cornerstone of all our actions and
                decisions.
            </p>
        </div>
    </>
    return (<>
    <PageHero body={txtHEroBody}/>
        <section className="container text-center">
            <h2>
                Our Company
            </h2>
            <p>
                We are a premier IT solutions provider with over 15 years of industry expertise. Our team of seasoned professionals delivers comprehensive technology solutions that drive business growth and innovation. We combine deep technical knowledge with industry best practices to help organizations navigate the complex digital landscape.
            </p>
        </section>
        <section className="container text-center">
            <h2>
                Why choose us?
            </h2>
            <ul className="adroit-list">
                <li>15+ Years Industry Experience</li>
                <li>Certified IT Professionals</li>
                <li>24/7 Support</li>
                <li>Industry-Leading Solutions</li>
            </ul>
        </section>
        <section className="container text-center">
            <h2>
                Our Core Services
            </h2>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
                <div className="card">
                    <h3>Managed Services</h3>
                    <p>Comprehensive IT management and support to keep your business running smoothly. We handle
                        everything from infrastructure maintenance to help desk support.</p>
                </div>

                <div className="card">
                    <h3>Cloud Solutions</h3>
                    <p>Expert cloud migration, optimization, and management services. We help you leverage the power of
                        cloud computing to enhance scalability and efficiency.</p>
                </div>

                <div className="card">
                    <h3>Cybersecurity</h3>
                    <p>Advanced security solutions to protect your digital assets. Our comprehensive security services
                        include threat detection, prevention, and incident response.</p>
                </div>

                <div className="card">
                    <h3>Data Governance</h3>
                    <p>Robust data management and compliance solutions. We ensure your organization meets regulatory
                        requirements while maximizing data value.</p>
                </div>
<div></div>
                <div className="card">
                    <h3>Digital Transformation</h3>
                    <p>Strategic guidance and implementation services to modernize your business. We help organizations
                        embrace digital technologies and stay competitive.</p>
                </div>

                <div className="card">
                    <h3>Compliance</h3>
                    <p>Expert guidance on industry regulations and standards. We help ensure your IT infrastructure
                        meets all necessary compliance requirements.</p>
                </div>
            </div>
        </section>
        <ContactUs/>
    </>)
}
export default OurCompany