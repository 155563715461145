import PageHero from "../components/sections/PageHero";
import AutomationImage from "assets/images/auto-hero.svg"
import SectionCard from "../components/sections/SectionCard";
import RPA from "assets/images/Artificial-Intelligence.svg"
import ProcessImage from "assets/images/process.svg"
import IntegrationImage from "assets/images/integration.svg"
import ContactUs from "../components/sections/ContactUs";

const Automation = () => {
    const txtOne = <>Streamline your operations with cutting-edge RPA solutions and intelligent process automation.</>
    const txtTwo = <>Leverage our deep expertise in leading RPA platforms:
    <ul className="adroit-list">
        <li><strong>UiPath</strong> - Enterprise-grade automation</li>
        <li><strong>Automation Anywhere</strong> - Intelligent automation solutions</li>
        <li><strong>Power Automate</strong> - Microsoft's low-code automation platform</li>
    </ul>
    </>
    const txtThree = <>End-to-end process automation solutions:
        <ul className="adroit-list">
            <li>Workflow automation</li>
            <li>Document processing</li>
            <li>Legacy system integration</li>
            <li>Custom automation solutions</li>
        </ul>
        </>
    const txtFour = <>Seamless data integration platforms:
    <ul className="adroit-list">
        <li><strong>Incorta</strong> - Real-time analytics</li>
        <li>ETL/ELT solutions</li>
        <li>Data warehouse automation</li>
        <li>API integrations</li>
    </ul>
    </>
    return (
        <>
            <PageHero image={AutomationImage} title="Transforming Business Through Automation" body={txtOne} />
            <SectionCard tag="Robotic Process Automation" image={RPA} body={txtTwo} isImageLeft={false} />
            <SectionCard tag="Process Automation" image={ProcessImage} body={txtThree} />
            <SectionCard tag="Data Integration" image={IntegrationImage} body={txtFour} isImageLeft={false} />
            <ContactUs/>

        </>
    )
}

export default Automation;