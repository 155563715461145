import SectionCard from "../components/sections/SectionCard";
import DataAnalysis from "../assets/images/data-analysis.svg";
import Governance from "assets/images/data-visualization.svg";
import DataCompliance from "assets/images/report-analysis.svg"
import DataMigration from "assets/images/Data-Processing.svg"
import Auditing from "assets/images/auditing.svg"
import ContactUs from "../components/sections/ContactUs";
import PageHero from "../components/sections/PageHero";

const DataLifeCycle = () => {
    const txtOne=<>The Data Lifecycle and Governance framework encompasses the complete journey of data from its creation/collection through storage, processing, sharing, archival, and eventual deletion, while ensuring proper management at each stage.</>
    const txtTwo = <>At Adroit Systems, we excel in delivering comprehensive Data Lifecycle Management (DLM) solutions and best industry specific practices that empower your business with robust, <strong>end-to-end data governance</strong>. From data creation and storage to utilization and archiving, our expert team ensures your data is managed efficiently and securely throughout its entire lifecycle.</>
    const txtThree = <>Ensure your business meets industry-specific regulations with proven practices in comprehensive data compliance procedures. Our expert team helps you navigate and adhere to policies such as <strong>GDPR</strong>, <strong>PII</strong>, <strong>HIPAA</strong>, <strong>FINRA</strong>, <strong>CCPA</strong> and more. We implement robust data compliancet measures, conduct regular audits, and provide ongoing support to keep your data secure and compliant. Trust Adroit Systems to safeguard your business against compliance risks and maintain regulatory standards across all sectors.</>
    const txtFour = <>Experience seamless data migration from multiple sources and targets. We ensure <strong>full fidelity</strong> and maintain a clear <strong>data chain of custody</strong> throughout the entire process. Our expert team guarantees accurate, secure, and efficient data transfers, preserving <strong>data integrity</strong> and minimizing downtime. Rely on Adroit Systems to handle your complex data migrations with precision and care.</>
    const txtFive = <>Achieve seamless audit readiness with our experts in data compliance practices. We offer comprehensive <strong>assessments</strong>, implement industry <strong>best practices</strong>, and ensure strict adherence to industry specific regulations. Our dedicated team prepares your business for audits with <strong>robust documentation</strong> and <strong>SOPs</strong>. Trust Adroit Systems to keep your data compliant and your business audit-ready.</>

    return <>
        <PageHero image={DataAnalysis} title="Data Lifecycle" subtitle="Empowering Your Data Journey." body={txtOne}/>
        <SectionCard head="Industry-Leading Data Lifecycle Management" tag="Powering Your Data Journey, End-to-End" body={txtTwo} image={Governance} />
        <SectionCard tag="Data Compliance" body={txtThree} image={DataCompliance} isImageLeft={false} />
        <SectionCard tag="Data Migration" body={txtFour} image={DataMigration} />
        <SectionCard tag="Data Compliance Audit Readiness" body={txtFive} image={Auditing} isImageLeft={false} />
        <ContactUs/>
    </>
}

export default DataLifeCycle