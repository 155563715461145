import PageHero from "../components/sections/PageHero";
import DataCenter from "assets/images/Data-Center.svg";
import SectionCard from "../components/sections/SectionCard";
import InfraImage from "assets/images/Hero-3.svg"
import SecurityImage from "assets/images/cybersecurity.svg"
import HelpDeskImage from "assets/images/customer-service.svg"
import ContactUs from "../components/sections/ContactUs";
const Managed = () => {
    const txtOne = <>Transform your IT infrastructure with our enterprise-grade managed services. We handle the complexity of IT, so you can focus on growing your business.</>
    const txtTwo = <>EProactive monitoring and management of your entire IT infrastructure, ensuring optimal performance and reliability.
    <ul className="adroit-list">
        <li>24/7 Network Monitoring & Alert Management</li>
        <li>Server Administration (Windows & Linux)</li>
        <li>Cloud Infrastructure (AWS, Azure, GCP)</li>
        <li>Storage Management & Optimization</li>
        <li>Backup & Disaster Recovery Planning</li>
        <li>Network Performance Optimization</li>
        <li>Virtualization Services (VMware, Hyper-V)</li>
        <li>Infrastructure Documentation & Reporting</li>
    </ul>
    </>
    const txtThree = <>Comprehensive security solutions to protect your business from evolving cyber threats and ensure compliance.
    <ul className="adroit-list">
        <li>Advanced Threat Detection & Response</li>
        <li>24/7 Security Operations Center (SOC)</li>
        <li>Vulnerability Assessment & Penetration Testing</li>
        <li>Endpoint Detection & Response (EDR)</li>
        <li>Email & Web Security Solutions</li>
        <li>Security Information & Event Management (SIEM)</li>
        <li>Compliance Management (HIPAA, PCI, SOC2)</li>
        <li>Regular Security Awareness Training</li>
    </ul>
    </>
    const txtFour = <>Multi-tiered technical support delivering fast, efficient resolution to all your IT issues.
    <ul className="adroit-list">
        <li>24/7/365 Technical Support Availability</li>
        <li>Multi-channel Support (Phone, Email, Chat)</li>
        <li>Remote & On-site Technical Assistance</li>
        <li>Incident Management & Escalation</li>
        <li>Asset & Inventory Management</li>
        <li>User Account Management</li>
        <li>Software Installation & Updates</li>
        <li>Monthly Performance Reporting</li>
    </ul>
    </>
    return (
        <>
            <PageHero image={DataCenter} title="Comprehensive IT Managed Services" body={txtOne} />
            <SectionCard head="Infrastructure Management" body={txtTwo} image={InfraImage} hash="cloud"/>
            <SectionCard head="Cybersecurity Services" body={txtThree} image={SecurityImage} isImageLeft={false} />
            <SectionCard head="Help Desk Support" body={txtFour} image={HelpDeskImage}/>
            <ContactUs/>

        </>
    )
}

export default Managed;