const SectionCard = ({ head, tag, body, image, isImageLeft = true, hash}) => {

    const txtRightImageLeft = () => {
        return <section className="container mx-auto px-4 py-12">
            <div id={hash} className="flex flex-col lg:flex-row items-center gap-8">

                {/* Text Content - Full width on small screens, half width on large */}
                <div className="w-full lg:w-1/2 order-2 lg:order-1">
                    <h2>
                        {head}
                    </h2>
                    <h3>
                        {tag}
                    </h3>
                    <p className="text-lg mb-6">
                        {body}
                    </p>
                </div>

                {/* Image Container - Full width on small screens, half width on large */}
                <div className="w-full lg:w-1/2 order-1 lg:order-2 relative">
                    <div
                        className="absolute top-0 z-[-2] h-full w-full bg-transparent bg-[radial-gradient(100%_100%_at_50%_50%,rgba(219,50,54,0.13)_0,rgba(219,50,54,0)_50%,rgba(219,50,54,0)_100%)]"></div>
                    <img
                        src={image}
                        alt={head}
                        className="w-full h-auto "
                    />
                </div>
            </div>
        </section>
    }
    const txtLeftImageRight = () => {
        return     <section className="container mx-auto px-4 py-12">
            <div id={hash} className="flex flex-col lg:flex-row items-center gap-12 relative">
                <div
                    className="absolute top-0 z-[-2] h-full w-full bg-transparent bg-[radial-gradient(100%_100%_at_50%_50%,rgba(219,50,54,0.13)_0,rgba(219,50,54,0)_50%,rgba(219,50,54,0)_100%)]"></div>
            {/* Image Container - Left on desktop, top on mobile */}
            <div className="w-full lg:w-1/2 order-1 ">
                 <div className="relative">

                    <img
                        src={image}
                        alt={head}
                        className=" w-full h-auto"
                    />
                </div>
            </div>
            {/* Text Content - Right on desktop, bottom on mobile */}
            <div className="w-full lg:w-1/2 order-2 space-y-6">

                <h2>
                    {head}
                </h2>
                <h3>
                    {tag}
                </h3>
                <p className="text-lg mb-6">
                    {body}
                </p>
            </div>
        </div></section>
    }
    return( isImageLeft? txtRightImageLeft(): txtLeftImageRight() )

}
export default SectionCard;
