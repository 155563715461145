import OurImage from "assets/images/Diversity.svg";
const WhoWeAre = () => {
    return (
        <section>
            <div className="container">
                <div className="lg:flex items-center gap-5 justify-between relative">
                    <div
                        className="absolute top-0 z-[-2] h-full w-full bg-transparent bg-[radial-gradient(100%_100%_at_50%_50%,rgba(219,50,54,0.13)_0,rgba(219,50,54,0)_50%,rgba(219,50,54,0)_100%)]"></div>
                    <div className="w-full md:w-5/12 lg:w-4/12 mx-auto">
                        <img src={OurImage} alt="Who we are?" />
                    </div>
                    <div className="mx-auto text-center">
                        <h2>Who we are</h2>
                        <h3 className="text-2xl mb-2">
                            <span className="text-5xl text-primary">+15</span>{" "}
                            Years Experience
                        </h3>
                        <p className="max-w-xl mx-auto">
                            With over 15 years of experience in IT consulting,
                            data compliance, and governance, we are one of the
                            fastest-growing companies in Asia. Our mission is to
                            support businesses and individuals with our
                            expertise and excellence.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhoWeAre;
