import SectionCard from "components/sections/SectionCard";
import WorkSpace from 'assets/images/mobile-testing-33.svg'
import GrowBusiness from "assets/images/chart_climb.svg"
import VideoCall from "assets/images/virtual-meeting.svg"
import MDM from "assets/images/mdm2.svg"
import ContactUs from "../components/sections/ContactUs";
import OfficeWork from "assets/images/office-work.svg"
import PageHero from "../components/sections/PageHero";

const ModernWorkplace = () => {
	const txtOne = <>The modern workspace is a hybrid ecosystem that blends physical and digital environments, shaped significantly by recent technological advances and changing work cultures.</>
	const txtTwo =<>Experience a technologically advanced, flexible environment where cutting-edge digital tools
		and cloud services enable seamless collaboration, remote work, and enhanced productivity,
		all supported by robust security measures.</>
	const txtThree = <>Unlock your business's full potential with our cloud services. Seamlessly scalable, ultra-secure and cost-effective. Our cloud solutions, ensure you stay ahead in today’s fast-paced market.
		Our team of experts specializes in delivering top-tier cloud solutions, including <strong>Microsoft 365</strong>, <strong>AWS</strong> and <strong>Google Workspace</strong>.</>
	const txtFour = <>Seamless communication and efficient collaboration are not just advantages—they're essential. Our team of consultants can be engaged to empower organizations to transform their workflows and enhance productivity.<br/>By understanding your team’s specific needs, you can choose the tool that best enhances your productivity and collaboration. Embrace the future of work with leading solutions like <strong>Microsoft Teams</strong>, <strong>Zoom</strong>, <strong>Slack</strong>, <strong>WebEx</strong> and <strong>Google Workspace</strong> which will keep your team connected, productive and engaged.</>
	const txtFive = <>In today's fast-paced environment, where agility and security solutions are constantly advancing, managing your Enterprise’s infrastructure can be challenging.<br/>We partner with industry-leading solution providers to offer customized solutions that meet your business needs. Our expertise includes delivering MDM (Mobile Device Management) solutions through top platforms like <strong>Microsoft</strong>, <strong>VMware</strong>, <strong>JAMF</strong>, and <strong>Ivanti</strong>.</>
	return (
		<>
			<PageHero image={OfficeWork} subtitle="Designing the future of work, Today." title="Modern Workplace" body={txtOne} />
			<SectionCard head='Workspace for the Digital Age' tag='Empower Your Team. Secure Your Future.' body={txtTwo} image={WorkSpace} />
			<SectionCard body={txtThree} image={GrowBusiness} isImageLeft={false}/>
			<SectionCard body={txtFour} image={VideoCall}/>
			<SectionCard hash="mdm" tag="Mobile Device Management" body={txtFive} image={MDM} isImageLeft={false}/>
			<ContactUs/>
		</>
	);
};

export default ModernWorkplace;
