
const PageHero = ({ title, subtitle, body, image }) => {
	return (
		<>
            <section className="relative table w-full py-36 lg:py-24 overflow-hidden">
                {/*<div className="absolute inset-0 bg-gradient-to-r from-slate-300 to-slate-500 opacity-20"></div>*/}
                <div
                    className="absolute top-0 z-[-2] h-screen w-screen bg-transparent bg-[radial-gradient(100%_50%_at_50%_0%,rgba(219,50,54,0.13)_0,rgba(219,50,54,0)_50%,rgba(219,50,54,0)_100%)]"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-5">
                        <img src={image} alt="" className="w-full lg:w-1/3 mx-auto"/>
                        <h2 className=" mb-6 md:text-4xl text-3xl md:leading-normal leading-normal font-medium ">{title}</h2>
                        <h3>{subtitle}</h3>
                        <p className="text-lg max-w-xl mx-auto">{body}</p>
                    </div>
                </div>

            </section>
            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white ">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
			</svg>
		</div>
	</div>
</>
	);
};

export default PageHero;
