import React from "react";
import { NavLink } from "react-router-dom";
import ContactImg from "assets/images/Receptionist.svg";
import { PhoneIcon } from "assets/icons/PhoneIcon";

const ContactUs = () => {
    return (
        <section>
            <div className="container">
                <div className="lg:flex items-center gap-5 justify-between relative">
                    <div
                        className="absolute top-0 z-[-2] h-full w-full bg-transparent bg-[radial-gradient(100%_100%_at_50%_50%,rgba(219,50,54,0.13)_0,rgba(219,50,54,0)_50%,rgba(219,50,54,0)_100%)]"></div>
                    <div className="mx-auto text-center *:mb-5">
                        <h2>Have Question? Get in touch!</h2>
                        <p className="max-w-xl mx-auto">
                            For inquiries and more information, please don't
                            hesitate to contact us. Our team is ready to assist
                            you and ensure you have all the details you need.
                        </p>
                        <div>
                            <NavLink to="/contact-us" className="btn">
                                <PhoneIcon /> Contact Us
                            </NavLink>
                        </div>
                    </div>
                    <div className="w-full md:w-5/12 lg:w-5/12 mx-auto">
                        <img src={ContactImg} alt="Contact Us" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
