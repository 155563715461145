export const MenuData = [
	{
		title: "Who we are",
		url: null,
		subItems: [
			{
				title: "Our Company",
				url: "/about-us",
			}
		],
	},
	{
		title: "Our Services",
		url: null,
		subItems: [
			{
				title: "Managed Services",
				url: "/managed-services",
			},
			{
				title: "Data Lifecycle",
				url: "/data-lifecycle",
			},
			{
				title: "Process Automtion",
				url: "/automation",
			},
			{
				title: "Mobile Device Management",
				url: "/modern-workspace#mdm",
			},
			{
				title: "Cloud Migrations",
				url: "/managed-services#cloud",
			},
			{
				title: "Modern Workspace",
				url: "/modern-workspace",
			},
		],
	},
	{
		title: "Contact Us",
		url: "/contact-us",
		subItems: []
	},
];
