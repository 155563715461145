import { ServicesData } from "assets/data/ServicesData";
import { NavLink } from "react-router-dom";

const OurServices = () => {
    return (
        <section>
            <div className="container">
                <h2 className="text-center">What we do</h2>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
                    {ServicesData.map((service, index) => (
                        <div className="card group" key={index}>
                            <div>
                                <img src={service.Image} alt={service.title} />
                            </div>
                            <h3 className="group-hover:text-primary">
                                {service.title}
                            </h3>
                            <p>{service.description}</p>
                            <NavLink
                                className="group-hover:text-primary duration-500"
                                to={service.url}
                            >
                                Learn More
                            </NavLink>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default OurServices;
