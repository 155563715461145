import PageHero from "../components/sections/PageHero";
import {useState} from "react";
import ThankYou from "../components/sections/ThankYou";
import AlertBar from "../components/common/AlertBar";
import useAxios from "../hooks/useAxios";


const Contact = () => {
    const [inquiry, setInquiry] = useState({
        email: "",
        subject: "",
        message: "",
    });
    const axios = useAxios()
    const [isError, setIsError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const onEmailChange = (e) => {
        setInquiry((old) => {return {...old, email:e.target.value}});
    }
    const onSubjectChange = (e) => {
        setInquiry((old) => {return {...old, subject:e.target.value}});
    }
    const onMessageChange = (e) => {
        setInquiry((old) => {return {...old, message:e.target.value}});
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setShowLoading(true);
        try{

            const response = await axios.post("/contact.php",JSON.stringify(inquiry))
            if (response.status === 200){
                setShowSuccess(true)
            }
        }
        catch (error) {
            console.log("this is error", error );
            setIsError(true);
        }
        finally {
            setShowLoading(false);
        }


    }
    return (
        <>
        <PageHero title="Get in touch!" subtitle="Send us a message" body="For inquiries and more information, please don't hesitate to contact us. Our team is ready to assist you and ensure you have all the details you need."/>
            {showSuccess ? (<ThankYou />):(
            <section className="container">
                <div className="py-1 lg:py-8 px-4 mx-auto max-w-screen-md">
                    <form action="#" className="space-y-8" onSubmit={handleSubmit}>
                        {isError && (<AlertBar msg="Something went wrong. Please try again later" />)}
                        <div>
                            <label htmlFor="email"
                                   className="block mb-2 font-medium">Your
                                email</label>
                            <input type="email" id="email"
                                   className="shadow-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                                   placeholder="your@email.com" required onChange={onEmailChange}  />
                        </div>
                        <div>
                            <label htmlFor="subject"
                                   className="block mb-2  font-medium  ">Subject</label>
                            <input type="text" id="subject"
                                   className="block p-3 w-full bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary focus:border-primary"
                                   placeholder="Let us know how we can help you" required onChange={onSubjectChange}  />
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="message"
                                   className="block mb-2 font-medium">Your
                                message</label>
                            <textarea id="message" rows="6"
                                      className="block p-2.5 w-full bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary focus:border-primary"
                                      placeholder="Leave a message..." onChange={onMessageChange} ></textarea>
                        </div>
                        {showLoading? (
                            <button className="btn-loading">Sending...</button>
                        ): (<button type="submit" className="btn">Send message</button>)}


                    </form>
                </div>
            </section>
                )
            }
        </>
    )
}

export default Contact