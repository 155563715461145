import {
	Popover,
	PopoverButton,
	PopoverPanel,
} from "@headlessui/react";
import { BarsIcon } from "assets/icons/BarsIcon";
import { CloseIcon } from "assets/icons/CloseIcon";
import LogoLg from "assets/images/Logo_lg_colo.svg"
import LogoSm from "assets/images/Logo_sm_color.svg"
import { useEffect } from "react";
import { MenuData } from "assets/data/MenuData";
import { NavLink } from "react-router-dom";

export const Header = () => {
	// eslint-disable-next-line no-restricted-globals

	useEffect(() => {

		function windowScroll() {
			// eslint-disable-next-line no-restricted-globals
			let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
			const MenuLg = document.getElementById("lg-menu");
			const SmallLogo = document.getElementById("small-logo");
			if (
				document.body.scrollTop >= 75 ||
				document.documentElement.scrollTop >= 75
			) {
				// header?.classList.add("shadow-sm");
				// header?.classList.add("bg-white");
				MenuLg.classList.add("menu-lg-bg");
				if (width <= 640){
					SmallLogo.classList.remove("hidden");					SmallLogo.classList.remove("logo-hide");
					SmallLogo.classList.add("logo-show");
				}
			} else {
				// header?.classList.remove("shadow-sm");
				// header?.classList.remove("bg-white");
				MenuLg.classList.remove("menu-lg-bg");
				if (width <= 640){
					SmallLogo.classList.add("logo-hide");
					SmallLogo.classList.remove("logo-show");
				}
			}
		}
		window.addEventListener("scroll", windowScroll);
		window.scrollTo(0, 0);
		return () => {
			window.removeEventListener("scroll", windowScroll);
		};
	}, []);

	return (
		<header id="header" className="fixed w-full z-999">
			<div className="container">
				<div className="w-full flex items-center gap-3">
					<NavLink to="/" className="w-40 lg:w-52">
						<img
							src={LogoLg}
							className="w-full hidden md:block"
							alt="Adroit Systems LLC FZ"
						/>
						<img
							id="small-logo"
							src={LogoSm}
							className="opacity-0 md:hidden w-20 transition-all duration-300 ease-in-out"
							alt="Adroit Systems LLC FZ"
						/>
					</NavLink>
					<div className="grow flex lg:justify-center items-center lg:flex-row flex-row-reverse">
						{/* Large Version Menu Bar */}
						<div id="lg-menu" className="hidden lg:flex gap-10 py-3 px-5">
							{MenuData.map((menu, index) => {
								if (menu.subItems.length === 0) {
									return (
										<NavLink key={index} to={menu.url} className="text-lg hover:text-primary outline-none">
											{menu.title}
										</NavLink>
									)
								} else {
									return <Popover key={index}>
										{({open}) => (
											<>
												<PopoverButton
													className={`text-lg hover:text-primary outline-none ${
														open ? "text-primary" : ""
													}`}
												>
													{menu.title}
												</PopoverButton>
												<PopoverPanel
													transition
													anchor="bottom start"
													className="z-999 top-48 shadow-sm data-[closed]:opacity-0 rounded-lg absolute ease-in-out duration-300 p-3 bg-white border border-gray-100 flex flex-col gap-3"
												>
													{({close}) =>
														menu.subItems.map(
															(item, index) => (
																<NavLink
																	key={index}
																	to={item.url}
																	className="hover:text-primary"
																	onClick={() =>
																		close()
																	}
																>
																	{item.title}
																</NavLink>
															)
														)
													}
												</PopoverPanel>
											</>
										)
										}
									</Popover>
								}
							})}
						</div>

						{/* Mobile Version Menu Drawer */}
						<div className="lg:hidden">
							<Popover>
								{({ open }) => (
									<>
										<PopoverButton className="outline-none">
											{open ? (
												<CloseIcon className="size-10" />
											) : (
												<BarsIcon className="size-10" />
											)}
										</PopoverButton>
										<PopoverPanel
											transition
											className="absolute shadow-sm bg-white w-screen left-0 top-24 py-5 px-3 lg:hidden duration-500 data-[closed]:opacity-0 ease-in-out"
										>
											{({ close }) =>
												MenuData.map((menu, index) => (
													<div
														key={index}
														className="pl-5"
													>
														{
															menu.subItems.length === 0? (
																<h6 className="text-lg font-semibold text-primary">
																	<NavLink to={menu.url}>{menu.title}</NavLink>
																</h6>
															):(<>
																<h6 className="text-lg font-semibold text-primary">
																	{menu.title}
																</h6>
																<div className="flex-col flex">
																	{menu.subItems.map(
																		(
																			item,
																			index
																		) => (
																			<NavLink
																				key={
																					index
																				}
																				className="p-3 hover:text-primary"
																				to={
																					item.url
																				}
																				onClick={() =>
																					close()
																				}
																			>
																				{
																					item.title
																				}
																			</NavLink>
																		)
																	)}
																</div>
																</>
															)
														}
													</div>
												))
											}
										</PopoverPanel>
									</>
								)}
							</Popover>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};
