import CheckAnimated from "assets/images/animated-check.svg"
const ThankYou = () => {

    return (<>
        <section className="container">
            <div className="flex flex-col items-center text-center gap-8 relative">
                <div
                className="absolute top-0 z-[-2] h-full w-full bg-transparent bg-[radial-gradient(100%_100%_at_50%_50%,rgba(219,50,54,0.13)_0,rgba(219,50,54,0)_50%,rgba(219,50,54,0)_100%)]"></div>
                <img src={CheckAnimated} alt="Animated Check" className="w-[200px]"/>
                <h2>
                    Thank you for reaching out.
                </h2>
                <h3>
                    <br/> We've received your message and will respond shortly.
                </h3>
            </div>
        </section>
    </>)
}

export default ThankYou
