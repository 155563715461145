import { Route, Routes } from "react-router-dom";
import { Layout } from "layouts/Layout";
import { Home } from "pages/Home";
import ModernWorkplace from "pages/ModernWorkplace";
import DataLifeCycle from "./pages/DataLifeCycle";
import Automation from "./pages/Automation";
import Managed from "./pages/Managed";
import OurCompany from "./pages/OurCompany";
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";
import WhatsApp from "./components/common/WhatsApp";
import Contact from "./pages/Contact";

function App() {
	return (
		<div className="App">
			<ScrollToHashElement alignToTop={true} behavior="smooth"/>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="/modern-workspace">
						<Route index element={<ModernWorkplace />} />
					</Route>
					<Route path="/data-lifecycle">
						<Route index element={<DataLifeCycle/>}/>
					</Route>
					<Route path="/automation">
						<Route index element={<Automation/>}/>
					</Route>
					<Route path="/managed-services">
						<Route index element={<Managed/>}/>
					</Route>
					<Route path="/about-us">
						<Route index element={<OurCompany/>}/>
					</Route>
					<Route path="/contact-us">
						<Route index element={<Contact/>}/>
					</Route>
				</Route>
			</Routes>
			<WhatsApp/>
		</div>
	);
}

export default App;
