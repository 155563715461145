import WhatsAppIcon from "assets/images/whatsapp.svg"

const WhatsApp = () => {
    return (
        <>
            <a target="_blank" rel="noreferrer" href="https://wa.me/971585237648?text=Hi%2C%0AI%27m%20in%20need%20of%20IT%20services." className="fixed h-10 w-10 right-10 bottom-10">
                <img src={WhatsAppIcon} alt="WhatsApp"/>
            </a>
        </>
    )
}

export default WhatsApp;